<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    :filterFields="filterFields"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('ServerCreate')"
    @edit="(id) => onEdit('ServerEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'Servers',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Servers',
      tableCaption: 'Сервера',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Сервера',
        },
      ],
      tableHeaders: [
        {
          text: 'Имя',
          alias: 'name',
          order: 'name',
        },
        { text: 'Описание', alias: 'description', order: 'description' },
        { text: 'IP-адрес', alias: 'ip', order: 'ip' },
        { text: 'Адрес', alias: 'addr', order: 'addr' },
        { text: 'Создать при регистрации', alias: 'isCreateOnReg', order: 'isCreateOnReg' },
        { text: 'Хост', alias: 'hostName', order: 'hostName' },
        { alias: 'actions' },
      ],
      filterFields: [
        { text: 'Имя', alias: 'name', },
        { text: 'IP-адрес', alias: 'ip' },
        { text: 'Адрес', alias: 'addr' },
        { text: 'Хост', alias: 'hostName' },
    ],
    };
  },
};
</script>
